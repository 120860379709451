import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="./logo192.png" className="App-logo" alt="logo" />
        <p>
          Welcome to Futurestar.
        </p>
        <div className="Links-container">
          <a className="Links" target="_blank" rel="noreferrer" href="https://instagram.com/futurestar.in?igshid=NzZlODBkYWE4Ng==">
              <img src="./logos/instagram.png" alt="instagram"></img>
            </a>
            
            <a className="Links" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/futurestarofficial/">
              <img src="./logos/linkedin.png" alt="linkedin"></img>
            </a>
        </div>
      </header>
    </div>
  );
}

export default App;
